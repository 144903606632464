.email-form {
    margin: 0 auto;
  }
  
  .label {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: white;
    margin-bottom: 16px;
  }

  .input-container {
    position: relative;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.2s;
    box-sizing: border-box;

    &.input-error {
        border: 2px solid #C92C6D; /* Rote Umrandung bei Fehler */
      }
    
    &:focus {
      border-color: #54BAB9;
      box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
    }
  }
  
  .button {
    width: 100%;
    padding: 10px;
    background-color: #54BAB9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    box-sizing: border-box;
    margin-bottom: 1.8rem;
    
    &:hover {
      background-color: #479190;
    }
  }

  .terms-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin: 2rem 0 2rem 0;
  }
  
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    appearance: none; // Entfernt das Standard-Browser-Styling
    border: 2px solid #949494;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
  
    &:checked {
      background-color: #54BAB9; // Ändert die Hintergrundfarbe beim Anklicken
      border-color: #54BAB9; // Ändert die Randfarbe beim Anklicken
    }
    &:checked::after {
        content: "✔"; // Haken-Symbol
        color: white;
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  
  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .termsText {
    color: #949494;
    font-size: 14px;
}

.error-message {
    color: #C92C6D;
    font-size: 13px;
  }

  .success-message {
    margin-top: 10px;
    color: #54BAB9;
    font-weight: bold;
  }