@import '../00-global/color.scss';

.signUp {
    background-color: var(--color-bg-quinary);
    padding: .5rem 3rem;

    &__description {
        font-size: 14px;
}
}

