@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

.renderPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  min-height: calc(100vh - 90px);
  flex-grow: 1;
  background-color: black;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #54BAB9;
}

ul {
  list-style-type: none;
  padding: 0;
}
.sm-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
}

.lg-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
}

h2 {
  color: white;
}
h4 {
  color: #949494;
}
.divider {
  margin: 2.5rem auto;
  width: 50%;
}